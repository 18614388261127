import * as vars from '../assets/_export.scss'
import mapKeys from 'lodash/mapKeys'

const locals = vars['locals'] ?? vars

const theme = Object.assign(mapKeys(locals, (_v, k) => '$'+k), {
  '$container-max-widths': {
    sm: locals['container-max-sm'],
    md: locals['container-max-md'],
    lg: locals['container-max-lg'],
    xl: locals['container-max-xl'],
  },
  '$colors': {
    blue: locals['blue'],
    white: locals['white'],
    black: locals['black'],
    red: locals['red'],
    orange: locals['orange'],
    yellow: locals['yellow'],
    green: locals['green'],
    teal: locals['teal'],
    pink: locals['pink'],
    purple: locals['purple'],
  },
  '$theme-colors': {
    danger: locals['danger'],
    dark: locals['dark'],
    info: locals['info'],
    light: locals['light'],
    primary: locals['primary'],
    secondary: locals['secondary'],
    success: locals['success'],
    warning: locals['warning'],
  },
})

export default theme
